


import { Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { RawLocation } from 'vue-router';

@Component
export default class AuthPage extends Vue {

  @Getter('authStore/isEnabledSignUp') isEnabledSignUp: boolean;
  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;

  public created(): void {
    this.isAuthenticated ? this.onAlreadyAuthenticated() : this.showAuthPopup();
  }

  public onAlreadyAuthenticated(): void {
    this.$router.push(this.getTargetRoute());
    this.hideAuthPopup();
  }

  public async hideAuthPopup(): Promise<void> {
    await this.$store.dispatch('authStore/setAuthPopupTargetRoute', null);
    await this.$store.dispatch('authStore/setStandaloneAuthVisibility', false);
  }

  public async showAuthPopup(): Promise<void> {
    await this.$store.dispatch('authStore/setAuthPopupTargetRoute', null);
    await this.$store.dispatch('authStore/setStandaloneAuthVisibility', true);
  }

  public getTargetRoute(): RawLocation {
    if (!this.isQueryEventPresent()) {
      return {
        name: 'event-list',
      };
    }
    return {
      name: 'promo-live',
      params: {
        eventId: this.$route.query.event as string,
      }
    };
  }

  public isQueryEventPresent(): boolean {
    if (!this.$route || this.$route.query || !this.$route.query.event || Array.isArray(this.$route.query.event)) {
      return false;
    }
    return /^\d+$/.test(this.$route.query.event);
  }
}
